import React from 'react'

const Terms = () => (
    <div>
        <h1>Privacy Policy</h1>
        <p>Placeholder for privacy policy..</p>
    </div>
)

export default Terms
